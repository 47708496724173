<template>
  <div class="container my-4">
    <div class="card shadow-sm border-round">
      <div class="card-body stampcard-container border-round">
        <div class="row">
          <div
            class="col-4 col-md-3 col-xl-2 mb-2"
            v-for="pointList of stampPointList"
            :key="pointList.id"
          >
            <div
              :class="[checkStamp(pointList) ? 'bg' : '', 'stamp']"
              @click="handleStampClick(pointList)"
            >
              <p
                class="stamped"
                v-if="checkStamp(pointList) && checkStamp(pointList).is_redeem"
              >
                <img
                  :src="$store.state.domain + $store.state.theme.themeLogoLine"
                  class=""
                />
              </p>
              <b-img
                v-if="
                  pointList.image_url && pointList.required_target_type == 1
                "
                class="stamp-img"
                :src="pointList.image_url"
              ></b-img>
              <div class="stamp-index text-white" v-else>
                {{ pointList.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalConfirm
      ref="redeemConfirmModal"
      :header="confirmRedeem.header"
      :text="confirmRedeem.text"
      :subText="confirmRedeem.subText"
      :button="confirmRedeem.button"
      @handler="onConfirm"
    />
    <ModalMessage
      ref="modalMessage"
      :text="text"
      @redeem="handleRedeem"
      :desc="desc"
      :redeemBtn="showBtnRedeem"
      closeText="ปิดหน้าต่างนี้"
    />
    <ModalSale
      ref="modalSale"
      :text="text"
      @redeem="onConfirm"
      :desc="desc"
      :redeemBtn="showBtnRedeem"
      closeText="ปิดหน้าต่างนี้"
    />
  </div>
</template>

<script>
import ModalSale from "@/components/alert-modal/ModalSaleInput";
export default {
  components: {
    ModalSale
  },
  data() {
    return {
      text: "",
      id: this.$route.params.id,
      showBtnRedeem: false,
      desc: "",
      stampPointList: [],
      stampPointUsedList: [],
      stampSelect: {},
      confirmRedeem: {
        header: "คุณต้องการแลกแสตมป์ที่คุณมีเป็นคูปองใช่หรือไม่ ?",
        text: "",
        subText: "",
        button: {
          true: {
            msg: "ยืนยัน"
          },
          false: {
            msg: "ยกเลิก"
          }
        }
      }
    };
  },
  created() {
    this.getStampCard();
  },

  methods: {
    async getStampCard() {
      const res = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/StampCard/${this.id}`
      );

      this.stampPointList = res.detail.detail.stampcardDetail;
      this.stampPointUsedList = res.detail.detail.userstampcardDetail;
    },
    checkStamp(stamp) {
      return this.stampPointUsedList.find(el => el.id == stamp.id);
    },
    handleStampClick(card) {
      if (this.checkStamp(card).is_redeem) return;
      this.showBtnRedeem = false;
      this.text = `
      <div class="color-primary text-center">เงื่อนไขการได้แสตมป์:</div>
     <div style='word-break: break-all;'>${card.description}</div>`;
      if (card.required_target_type == 1) {
        this.showBtnRedeem = this.checkStamp(card) ? true : false;
        this.text = `
      <div class="color-primary text-center">รายละเอียดของรางวัล:</div>
      <div style='word-break: break-all;'>${card.description}</div>`;
        this.desc = ``;
      }
      this.stampSelect = card;
      this.$refs.modalMessage.show();
    },

    async onConfirm(sale_id) {
      let payload = {};
      payload.stamp_card_redeem_type_id =
        this.stampSelect.stamp_card_redeem_type_id;
      payload.reference_id = this.stampSelect.reference_id;
      sale_id ? (payload.sales_no = sale_id) : false;
      const res_redeem = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/v1/StampCard/${this.$route.params.id}/${this.stampSelect.id}`,
        payload
      );
      this.showBtnRedeem = false;
      if (res_redeem.result) {
        this.text = `<div class='f-bold f-lg'>แลกของรางวัลเรียบร้อย</div>`;
        this.getStampCard();
      } else {
        this.text = `<div class='f-bold f-lg'>${res_redeem.message}</div>`;
      }
      this.$refs.modalSale.hide();
      this.$refs.modalMessage.show();
    },
    handleRedeemProduct() {},
    handleRedeem() {
      this.$refs.modalMessage.hide();
      if (this.stampSelect.stamp_card_redeem_type_id == 1) {
        return this.$refs.modalSale.show();
      }
      this.$refs.redeemConfirmModal.show();
    }
  }
};
</script>

<style lang="scss">
small {
  min-height: 20px;
  white-space: nowrap;
}

.border-round {
  border-radius: 25px;
  border: unset;
}
.stamp-index {
  height: 100%;
  top: 0;
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  font-size: 2.5rem;
  transform: rotate(25deg);
  align-items: center;
  justify-content: center;
}
.stamp-img {
  height: 80%;
  width: 80%;
  top: 10%;
  position: absolute;
  transform: rotate(25deg);
  left: 10%;
}
img.stamp-img {
  object-fit: contain;
  border-radius: 50%;
}

.stampcard-container {
  background-image: repeating-radial-gradient(
      circle at left center,
      transparent 0,
      #f8f8f8 20px
    ),
    repeating-linear-gradient(#ffffff, #ffffff);
  background-color: #f8f8f8;
}
.is-redeemed {
  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  position: absolute;
  // color: var(--font-color);
  z-index: 10;
  font-size: var(--text-xs);
  background: var(--font-primary-color);
  top: 38%;
  transform: rotate(320deg);
  left: 0;
  width: 100%;
  text-align: center;
}

.bg {
  background-color: var(--primary-color) !important;
}
.bg:before {
  background-color: var(--primary-color);
}

.stamp {
  position: relative;
  display: inline-block;
  width: 100%;
  // overflow: hidden;
  font-family: "Paytone One", sans-serif;
  font-size: 1.25em;
  text-align: center;
  background-color: whitesmoke;
  border-radius: 100%;
  -webkit-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.stamp:after {
  content: "";
  float: left;
  padding-bottom: 100%;
}

.stamp:before {
  content: "";
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  border: 2px solid white;
  border-radius: 100%;
}

.stamped {
  img {
    width: 50%;
    height: auto;
    object-fit: contain;
  }
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  width: 105%;
  padding: 0.25em;
  background: var(--font-primary-color);
  margin: 0;
  z-index: 1;
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
}
@media screen and (min-width: 350px) and (max-width: 767px) {
  .stamped {
    img {
      transform: translateY(-55%);
    }
    max-height: 30px;
  }
}
@media screen and (min-width: 300px) and (max-width: 349px) {
  .stamped {
    img {
      transform: translateY(-85%);
      // /* transform: translateY(-23%);
    }
    max-height: 20px;
  }
}
@media screen and (max-width: 300px) {
  .stamped {
    img {
      transform: translateY(-100%);
      // /* transform: translateY(-23%);
    }
    max-height: 20px;
  }
}
</style>
