<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="text-center">กรอกรหัส Sale ID.</div>
      <InputText
        v-model="saleID"
        textFloat=""
        placeholder="กรอกรหัส Sale ID."
        type="text"
        name="sale_id"
        maxlength="20"
        class="f-regular mb-2"
      />

      <b-button
        v-if="redeemBtn"
        variant="primary-theme"
        block
        @click="$emit('redeem', saleID)"
      >
        ยืนยันแลกรับของรางวัล</b-button
      >
      <b-button variant="outline-secondary" block @click="hide">
        {{ closeText }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText
  },
  props: {
    text: {
      required: false,
      type: String
    },
    desc: {
      required: false,
      type: String
    },
    closeText: {
      required: false,
      default: "ปิด"
    },
    redeemBtn: {
      required: false,
      default: false
    }
  },
  data() {
    return { saleID: "", showModal: false };
  },
  methods: {
    show() {
      this.saleID = "";
      this.showModal = true;
      this.$emit("show");
    },

    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}

</style>
